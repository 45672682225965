<template>
  <div class="container position-relative">
    <!-- begin::Device undefined -->
    <no-module-linked-alert
      v-if="!device"
      ref="noModuleLinkedAlert"
      class="col-12"
    />
    <!-- end::Device undefined -->

    <!-- begin::Device details -->
    <template v-else>
      <!-- begin::Loading -->
      <template v-if="isMounted === false || isLoading">
        <div class="loading-container loading-overlap">
          <div class="loading-backdrop rounded" />
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span class="float-left">Gathering data...</span>
              <span class="spinner spinner-primary ml-auto" />
            </div>
          </div>
        </div>
      </template>
      <!-- end::Loading -->

      <div class="row row-cols-1 row-cols-lg-3">
        <div class="col mt-3 border-bottom">
          <label class="text-upper">Serial</label>
          <div>
            <h5 class="overflow-ellipsis">
              {{ device.serial }}
            </h5>
          </div>
        </div>
        <div class="col mt-3 border-bottom">
          <label class="text-upper">Revision</label>
          <div>
            <h5 class="overflow-ellipsis">
              {{ device.type }}
            </h5>
          </div>
        </div>
        <div class="col mt-3 border-bottom">
          <label class="text-upper">Access point</label>
          <div>
            <h5 class="overflow-ellipsis">
              {{ device.access_point.ssid }} / {{ device.access_point.psk }}
            </h5>
          </div>
        </div>
        <div class="col mt-3 border-bottom">
          <label class="text-upper">Release</label>
          <div>
            <h5 class="overflow-ellipsis">
              {{ device.software }}
            </h5>
          </div>
        </div>
        <div class="col mt-3 border-bottom">
          <label class="text-upper">Auto update</label>
          <div>
            <span :class="['switch', { 'switch-success': device.type === 'MYLUXURA_MODULE' }]">
              <label>
                <input type="checkbox" :checked="device.type === 'MYLUXURA_MODULE'" disabled />
                <span />
              </label>
            </span>
          </div>
        </div>
        <div class="col mt-3 border-bottom">
          <label class="text-upper">Hardware</label>
          <div>
            <h5 class="overflow-ellipsis">
              <span>{{ (device.hardware && device.hardware.model) || 'Unknown' }}</span>
            </h5>
            <span>Shield: {{ (device.peripherals && device.peripherals.timed_shield !== undefined ? (device.peripherals.timed_shield === true ? 'Auto' : 'Manual') : '-') }}</span>
          </div>
        </div>
        <div class="col mt-3 border-bottom">
          <label class="text-upper">Wireless network</label>
          <div>
            <h5 class="overflow-ellipsis">
              <template v-if="device.type === 'MYLUXURA_MODULE'">
                <span v-if="!wirelessNetwork">N/A</span>
                <span v-else-if="wirelessNetwork.mode === 3">AP mode</span>
                <span v-else-if="wirelessNetwork.mode === 2 && wirelessNetwork.state !== 100">Disconnected</span>
                <span v-else>Connected</span>
              </template>
            </h5>
          </div>
        </div>
        <div class="col mt-3 border-bottom">
          <label class="text-upper">Wireless SSID</label>
          <div>
            <h5 class="overflow-ellipsis">
              <span v-if="!wirelessNetwork || wirelessNetwork.mode === 3">-</span>
              <span v-else>{{ wirelessNetwork.ssid }} ({{ `${wirelessNetwork.strength}%` }})</span>
            </h5>
          </div>
        </div>
        <div class="col mt-3 border-bottom">
          <label class="text-upper">Wireless IP</label>
          <div>
            <h5 class="overflow-ellipsis">
              <span v-if="!wirelessNetwork || wirelessNetwork.mode === 3">-</span>
              <span v-else>
                {{ wirelessNetwork.address }}

                <a :href="`http://${wirelessNetwork.address}`" target="_blank" class="svg-icon font-size-xs text-info text-hover-info-light">
                  <font-awesome-icon class="pt-2" :icon="['fas', 'external-link']" />
                </a>
              </span>
            </h5>
          </div>
        </div>
        <div class="col mt-3 border-bottom">
          <label class="text-upper">Wired network</label>
          <div>
            <h5 class="overflow-ellipsis">
              <span v-if="!wiredNetwork">N/A</span>
              <span v-else-if="wiredNetwork.state !== 100">Disconnected</span>
              <span v-else>Connected</span>
            </h5>
          </div>
        </div>
        <div class="col mt-3 border-bottom">
          <label class="text-upper">Wired IP</label>
          <div>
            <h5 class="overflow-ellipsis">
              <span v-if="!wiredNetwork || wiredNetwork.state !== 100">-</span>
              <span v-else>
                {{ wiredNetwork.address }}

                <a :href="`http://${wiredNetwork.address}`" target="_blank" class="svg-icon font-size-xs text-info text-hover-info-light">
                  <font-awesome-icon class="pt-2" :icon="['fas', 'external-link']" />
                </a>
              </span>
            </h5>
          </div>
        </div>
        <div class="col mt-3 border-bottom">
          <label class="text-upper">Remote IP</label>
          <div class="row">
            <h5 class="col overflow-ellipsis">
              <span v-if="!device.network || !device.network.remote_ip">-</span>
              <span v-else>{{ device.network.remote_ip }}</span>
            </h5>

            <div v-if="$auth.hasPermission('devices.get.geolocation') && device.network && device.network.remote_ip" class="col-auto pr-0">
              <button
                type="button"
                class="btn btn-icon btn-light-info btn-sm mt-n5"
                :disabled="actions.ip_geolocation.isLoading"
                @click.prevent="getIPGeolocation"
              >
                <i class="svg-icon">
                  <font-awesome-icon :icon="['fas', (actions.ip_geolocation.isLoading === true ? 'circle-notch' : 'map-marker-alt')]" :spin="actions.ip_geolocation.isLoading" />
                </i>
              </button>
            </div>
          </div>
        </div>

        <div class="col mt-3 border-bottom">
          <label class="text-upper">CPU load</label>
          <div>
            <h5 class="overflow-ellipsis">
              <span>{{ (ping && ping.cpu.load ? ping.cpu.load.join(', ') : '?') }}</span>
            </h5>
            <span v-if="ping" class="text-muted font-size-sm">{{ $moment.utc(ping._meta.created).fromNow() }}</span>
          </div>
        </div>

        <div class="col mt-3 border-bottom">
          <label class="text-upper">CPU temperature</label>
          <div>
            <h5 class="overflow-ellipsis">
              <span>{{ (ping && ping.cpu.temperature ? (`${(ping.cpu.temperature / 1000).toFixed(1)} &deg;C`) : '?') }}</span>
            </h5>
            <span v-if="ping" class="text-muted font-size-sm">{{ $moment.utc(ping._meta.created).fromNow() }}</span>
          </div>
        </div>

        <div class="col mt-3 border-bottom">
          <label class="text-upper">Disk usage</label>
          <div>
            <h5 class="overflow-ellipsis">
              <span>{{ (ping && ping.disk ? (`${(ping.disk.used / 1000 / 1000).toFixed(1)} MB / ${(ping.disk.size / 1000 / 1000).toFixed(1)} MB (${((ping.disk.used / ping.disk.size) * 100).toFixed(1)}%)`) : '?') }}</span>
            </h5>
            <span v-if="ping" class="text-muted font-size-sm">{{ $moment.utc(ping._meta.created).fromNow() }}</span>
          </div>
        </div>
      </div>
    </template>
    <!-- end::Device details -->
  </div>
</template>

<script>
import Equipment from '@/libs/classes/equipment';
import Device from '@/libs/classes/device';
import DevicePing from '@/libs/classes/device_ping';

import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';

import noModuleLinkedAlert from '@/components/pages/sunbeds/view/components/no-module-linked.alert.vue';

export default {
  mixins: [equipmentMixin],
  components: {
    noModuleLinkedAlert,
  },
  data () {
    return {
      isLoading: false,
      isMounted: false,

      actions: {
        ip_geolocation: {
          isLoading: false,
        },
      },

      ping: null,
    };
  },
  computed: {
    wiredNetwork () {
      if (!this.device || !this.device.network || !this.device.network.interfaces) {
        return null;
      }
      return this.device.network.interfaces.find((ipInterface) => ipInterface.interface === 'eth0');
    },
    wirelessNetwork () {
      if (!this.device || !this.device.network || !this.device.network.interfaces) {
        return null;
      }
      return this.device.network.interfaces.find((ipInterface) => ipInterface.interface.includes('wlan'));
    },
  },

  async mounted () {
    if (this.device) {
      this.$set(this, 'isLoading', true);
      try {
        this.$set(this, 'ping', await this.getLastPingOfDevice());
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
      } finally {
        this.$set(this, 'isLoading', false);
      }
    }

    await this.$nextTick();

    this.$set(this, 'isMounted', true);
  },

  methods: {
    async getLastPingOfDevice () {
      const response = await this.$ws.get(`${Equipment.uri}/${this.equipment._meta.guid}/device/ping`);
      return new DevicePing().merge(response);
    },

    async getIPGeolocation () {
      this.$set(this.actions.ip_geolocation, 'isLoading', true);
      try {
        const response = await this.$ws.get(`${Device.uri}/${this.device._meta.guid}/ip-geolocation`);
        this.$metronic.eventhub.emit('modals:layout:information:open', {
          title: 'IP Geolocation',

          // eslint-disable-next-line no-multi-str
          message: `<div class="row">
              <div class="col-6 col-lg-5 col-xl-4">IP address</div>
              <div class="col font-weight-bold overflow-ellipsis">${(this.device.network && this.device.network.remote_ip) || '-'}</div>
            </div>
            <div class="row">
              <div class="col-6 col-lg-5 col-xl-4">Country</div>
              <div class="col font-weight-bold overflow-ellipsis">${response.country_name} (${response.country_code})</div>
            </div>
            <div class="row">
              <div class="col-6 col-lg-5 col-xl-4">Region</div>
              <div class="col font-weight-bold overflow-ellipsis">${response.region_name}</div>
            </div>
            <div class="row">
              <div class="col-6 col-lg-5 col-xl-4">City</div>
              <div class="col font-weight-bold overflow-ellipsis">${response.city_name}</div>
            </div>`,
        });
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$set(this.actions.ip_geolocation, 'isLoading', false);
      }
    },
  },
};
</script>
